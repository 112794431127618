<template>
  <div v-if="!loading">
    <!-- objective list -->
    <v-row>
      <v-col>
        <p class="text-body-2 grey--text">
          You can select on what you want to filter.
        </p>

        <v-row>
          <v-col cols="5" class="d-flex align-center">
            <p class="text-body-2">Type:</p>

            <v-select
              v-model="selected_type"
              class="ml-4"
              :items="['All', 'Service Ticket', 'Internal Step']"
              dense
            />
          </v-col>
          <v-col class="d-flex align-center">
            <p v-if="selected_type === 'Service Ticket'" class="text-body-2">
              Service ticket type:
            </p>

            <v-select
              v-if="selected_type === 'Service Ticket'"
              v-model="selected_service_ticket_type"
              :items="[
                'All',
                'Payout Request',
                'Balance Request',
                'Payment Amount Change',
                'Payment Date Change',
                'Payment Collection',
                'Property Taxes',
                'Insurance',
                'Demand Letter',
                'Foreclosure',
                'Other',
              ]"
              class="ml-4"
              dense
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-expansion-panels v-model="panel" multiple>
              <!-- active objective list -->
              <v-expansion-panel class="bordered">
                <v-expansion-panel-header>
                  <div><v-icon>mdi-bullseye-arrow</v-icon> Active</div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-data-table
                    :headers="headers"
                    :items="active_objectives"
                    :items-per-page="-1"
                    item-key="id"
                    @click:row="handle_click_row"
                  >
                    <template v-slot:item.days_passed="{ item }">
                      {{ daysPassed(item.createdAt) }}
                    </template>

                    <template v-slot:item.day_limit="{ item }">
                      {{ item.new_day_limit }}
                    </template>

                    <template v-slot:item.type="{ item }">
                      {{
                        item.type === "Service Ticket"
                          ? item.service_ticket_type
                          : item.type
                      }}

                      {{
                        item.service_ticket_type === "Other"
                          ? ` - ${item.specified_service_ticket_type}`
                          : ""
                      }}
                    </template>
                  </v-data-table>

                  <v-btn class="mt-5" @click="handle_create_objective_btn">
                    create objective
                  </v-btn>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- completetd objective list -->
              <v-expansion-panel class="bordered">
                <v-expansion-panel-header>
                  <div><v-icon>mdi-check</v-icon> Completed</div>
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                  <v-data-table
                    :headers="
                      headers.filter((el) => el.value !== 'days_passed')
                    "
                    :items="completed_objectives"
                    :items-per-page="-1"
                    @click:row="handle_click_row"
                  >
                    <template v-slot:item.task_counted="{ item }">
                      {{ item.stg_obj_tsks.length }}
                    </template>

                    <template v-slot:item.day_limit="{ item }">
                      {{ item.new_day_limit }}
                    </template>

                    <template v-slot:item.type="{ item }">
                      {{
                        item.type === "Service Ticket"
                          ? item.service_ticket_type
                          : item.type
                      }}

                      {{
                        item.service_ticket_type === "Other"
                          ? ` - ${item.specified_service_ticket_type}`
                          : ""
                      }}
                    </template>
                  </v-data-table>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- archived objective list -->
              <v-expansion-panel class="bordered">
                <v-expansion-panel-header>
                  <div><v-icon>mdi-archive-outline</v-icon> Archived</div>
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                  <v-data-table
                    :headers="
                      headers.filter((el) => el.value !== 'days_passed')
                    "
                    :items="archived_objectives"
                    :items-per-page="-1"
                    @click:row="handle_click_row"
                  >
                    <template v-slot:item.task_counted="{ item }">
                      {{ item.stg_obj_tsks.length }}
                    </template>

                    <template v-slot:item.day_limit="{ item }">
                      {{ item.new_day_limit }}
                    </template>

                    <template v-slot:item.type="{ item }">
                      {{
                        item.type === "Service Ticket"
                          ? item.service_ticket_type
                          : item.type
                      }}

                      {{
                        item.service_ticket_type === "Other"
                          ? ` - ${item.specified_service_ticket_type}`
                          : ""
                      }}
                    </template>
                  </v-data-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- actions -->
    <v-row>
      <v-col>
        <v-btn
          v-if="view_type !== 'deal' && $route.query.view_type !== 'searched'"
          class="mr-5"
          @click="$router.push({ path: `/dashboard/admin/kanban` })"
        >
          Back
        </v-btn>

        <v-btn
          v-if="$route.query.view_type === 'searched'"
          @click="$router.push({ path: `/dashboard/admin/deal/read/${$route.query.deal_id}` })"
        >
          View Deal
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import filt from "@/plugins/filters";

export default {
  props: ["kanban_type", "stage_item_id", "view_type", "deal_id", "stg_itm"],

  data: () => ({
    loading: true,
    panel: [0],
    selected_type: "All",
    selected_service_ticket_type: "All",

    // headers
    headers: [
      { text: "Days Passed", value: "days_passed" },
      { text: "Subject", value: "subject" },
      { text: "Description", value: "description" },
      { text: "Status", value: "status" },
      { text: "Due Date", value: "day_limit" },
      { text: "Type", value: "type" },
    ],
  }),

  computed: {
    archived_objectives() {
      const filtered_objectives = this.filtered_objectives.filter(
        (objective) => objective.status === "Archived"
      );
      return filtered_objectives;
    },

    completed_objectives() {
      const filtered_objectives = this.filtered_objectives.filter(
        (objective) => objective.status === "Closed"
      );
      return filtered_objectives;
    },

    active_objectives() {
      const filtered_objectives = this.filtered_objectives.filter(
        (objective) => objective.status === "Active"
      );
      return filtered_objectives;
    },

    filtered_objectives() {
      switch (this.selected_type) {
        case "Service Ticket": {
          const ST_objectives = this.stg_itm.stg_objs.filter(
            (objective) => objective.type === "Service Ticket"
          );

          switch (this.selected_service_ticket_type) {
            case "Payout Request": {
              return ST_objectives.filter(
                (objective) =>
                  objective.service_ticket_type === "Payout Request"
              );
            }

            case "Balance Request": {
              return ST_objectives.filter(
                (objective) =>
                  objective.service_ticket_type === "Balance Request"
              );
            }

            case "Payment Amount Change": {
              return ST_objectives.filter(
                (objective) =>
                  objective.service_ticket_type === "Payment Amount Change"
              );
            }

            case "Payment Date Change": {
              return ST_objectives.filter(
                (objective) =>
                  objective.service_ticket_type === "Payment Date Change"
              );
            }

            case "Payment Collection": {
              return ST_objectives.filter(
                (objective) =>
                  objective.service_ticket_type === "Payment Collection"
              );
            }

            case "Property Taxes": {
              return ST_objectives.filter(
                (objective) =>
                  objective.service_ticket_type === "Property Taxes"
              );
            }

            case "Insurance": {
              return ST_objectives.filter(
                (objective) => objective.service_ticket_type === "Insurance"
              );
            }

            case "Demand Letter": {
              return ST_objectives.filter(
                (objective) => objective.service_ticket_type === "Demand Letter"
              );
            }

            case "Foreclosure": {
              return ST_objectives.filter(
                (objective) => objective.service_ticket_type === "Foreclosure"
              );
            }

            case "Other": {
              return ST_objectives.filter(
                (objective) => objective.service_ticket_type === "Other"
              );
            }

            default:
              return ST_objectives;
          }
        }

        case "Internal Step": {
          const IS_objectives = this.stg_itm.stg_objs.filter(
            (objective) => objective.type === "Internal Step"
          );
          return IS_objectives;
        }

        default:
          return this.stg_itm.stg_objs;
      }
    },
  },

  methods: {
    handle_create_objective_btn() {
      this.$router.push({
        path: `/dashboard/admin/kanban/${this.kanban_type}/stage-item/${this.stage_item_id}/objective/add`,
        query: { view_type: this.view_type },
      });
    },

    handle_click_row(value) {
      console.log(value);
      this.$router.push({
        path: `/dashboard/admin/kanban/${this.kanban_type}/stage-item/${this.stage_item_id}/objective/read/${value.id}`,
      });
    },

    daysPassed(created_date) {
      // specify the start date in mm/dd/yyyy format
      const startDate = new Date(created_date);

      // get the current system date
      const endDate = new Date();

      // calculate the time difference between the two dates
      const timeDiff = endDate.getTime() - startDate.getTime();

      // convert the time difference to days
      const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

      return daysDiff;
    },

    convertObjectiveDayLimit() {
      for (const stg_obj of this.stg_itm.stg_objs) {
        const date = new Date(stg_obj.createdAt);
        date.setDate(date.getDate() + stg_obj.day_limit);

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        const result = `${year}-${month}-${day}`;
        stg_obj.new_day_limit = result;
      }

      this.loading = false;
    },
  },

  mounted() {
    this.convertObjectiveDayLimit();
  },

  filters: {
    ...filt,
  },
};
</script>

<style>
.bordered {
  border: 1px solid #3c3c3c !important;
  background-color: transparent !important;
}
</style>
